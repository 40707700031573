import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { css } from '@emotion/core'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Button from '../components/button'
import StyledBackgroundHero from '../components/background-header'
import logo from '../images/logo.svg'
const IndexPage = ({ data }) => {
  const imageData = data.desktop.childImageSharp.fluid

  const hathaKlangschale = data.hathaKlangschale.childImageSharp.fluid
  const hathaIntense = data.hathaIntense.childImageSharp.fluid

  const ourStudio = data.ourStudio.childImageSharp.fluid

  const blogArticles = data.allContentfulBlogArtikel

  console.log(blogArticles)

  return (
    <Layout
      hero={
        <StyledBackgroundHero imageData={imageData}>
          <img width="300" src={logo} alt="Logo Yogastudio Olga Hilz" />
        </StyledBackgroundHero>
      }
    >
      <SEO title="Startseite" />

      <Hero
        title="Unser Angebot"
        description="Wir freuen uns, dass Du da bist. Entdecke unsere Yoga-Kursangebote in Harsefeld. Alle Yogakurse basieren auf dem Hatha Yoga Stil. Hier lernst Du Deinen Körper neu kennen,entwickelst Selbstvertrauen, Ausdauer und Gelassenheit. Werde auch Du zu einer noch besseren Version Deiner Selbst und wachse gemeinsam mit uns in eine Zukunft voller Frieden und Liebe."
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justif-content: space-between;
            gap: 1rem;

            @media (max-width: 720px) {
              flex-direction: column;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 1rem;
            `}
          >
            <Img
              css={css`
                height: 400px;
                width: 400px;
                margin-bottom: 2rem;
              `}
              fluid={hathaKlangschale}
            />
            <h3>Hatha Yoga mit Klangschalenbegleitung</h3>
            <p>
              Du liebst den Sound der Klangschalen un suchst beim Yoga einen Ort
              zum abschalten mit wohltuenden Ubungen fur deinen ganzen Körper?
              Dann solltest du unbedinat die Stunde bei Olga ausprobierer.
            </p>
            <Button inverted to="/kurse">
              Mehr dazu
            </Button>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 1rem;
            `}
          >
            <Img
              css={css`
                height: 400px;
                width: 400px;
                margin-bottom: 2rem;
              `}
              fluid={hathaIntense}
            />
            <h3>Hatha Yoga Intense</h3>
            <p>
              Du bist körperlich fit und suchst beim Yoga neben der Entspannung
              auch eine kleine Heraustorderung? Dann bist du bei den kraftvollen
              Hatha Yoga Stunden bei Ina genau richtig!
            </p>
            <Button inverted to="/hatha-yoga-intense">
              Mehr dazu
            </Button>
          </div>
        </div>
      </Hero>

      <Img
        css={css`
          height: 30vh;
        `}
        fluid={ourStudio}
      />

      <Hero
        title="Unser Studio"
        description="Unser Yogastudio erstreckt sich auf 160m2. Der große helle Raum bietet genügend Platz, um jedem Teilnehmer die Möglichkeit zu geben voll und ganz bei sich zu bleiben. Darum fasst ein Kurs bei uns auch nicht mehr als 15 Teilnehmer. In unserem Studio findest Du alles vor, was Du für den Yogaunterricht benötigst. Matten, Kissen, Klötze, Bänder, Decken, Stühle und Wasser werden für jeden Teilnehmer bereitgestellt. Du darfst natürlich auch Dein eigenes Equipment mitbringen. Auch Umkleidekabinen sind separat für Männer und Frauen vorhanden. Damit Du Dich individuell für die Yogastunde einrichten kannst, bitten wir Dich möglichst 15 Minuten vor Kursbeginn da zu sein. Wenn anschließend noch Zeit vorhanden ist, bietet sich auch die Möglichkeit auf eine Tasse Tee und ein nettes Gespräch zu bleiben, um einander näher kennenzulernen oder persönliche Fragen zu stellen."
      ></Hero>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justif-content: space-between;
          gap: 1rem;

          @media (max-width: 720px) {
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem;
            text-align: center;
          `}
        >
          <Img
            css={css`
              height: 400px;
              width: 400px;
              margin-bottom: 2rem;
            `}
            fluid={blogArticles.edges[0].node.featured_image.fluid}
          />
          <h3>{blogArticles.edges[0].node.title}</h3>
          <Button to={'/blog/' + blogArticles.edges[0].node.slug}>
            Weiterlesen
          </Button>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem;
            text-align: center;
          `}
        >
          <Img
            css={css`
              height: 400px;
              width: 400px;
              margin-bottom: 2rem;
            `}
            fluid={blogArticles.edges[1].node.featured_image.fluid}
          />
          <h3>{blogArticles.edges[1].node.title}</h3>
          <Button to={'/blog/' + blogArticles.edges[1].node.slug}>
            Weiterlesen
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Images {
    desktop: file(relativePath: { eq: "01_redesign_header.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    hathaKlangschale: file(relativePath: { eq: "02_hatha_klangschale.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hathaIntense: file(relativePath: { eq: "03_hatha_intense.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    ourStudio: file(relativePath: { eq: "studio/DSC00496.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    image4: file(relativePath: { eq: "small/05_LP_topic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image5: file(relativePath: { eq: "small/06_LP_topic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image6: file(relativePath: { eq: "small/07_LP_topic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allContentfulBlogArtikel(
      limit: 2
      sort: { fields: [published_at], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          featured_image {
            file {
              url
            }

            fluid(quality: 100, maxWidth: 720) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default IndexPage
